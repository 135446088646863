<template>
  <Form ref="form" class="space-y-4" @submit="getOtp">
    <Error
      class="p-4 mb-4 text-sm rounded-md bg-danger-100 text-danger-600"
      :errors="errors"
    />

    <MobileInput @update-calling-code="mobileNumberWithCallingCode = $event" />

    <p class="mt-2 text-sm text-warning-500">
      <span v-if="otpRequested"> {{ $t("check_otp_message") }} </span>
      <span v-else>{{ $t("login_otp_message") }}</span>
    </p>

    <!-- OTP -->
    <template v-if="otpRequested">
      <FormField :label="$t('one_time_password')">
        <FormTextbox
          v-model="otp"
          type="number"
          name="one time password"
          rules="required"
        />
      </FormField>

      <!-- Validate OTP -->
      <VyButton
        type="button"
        fluid
        theme="solid"
        color="primary"
        shape="rounded"
        size="lg"
        :loading="otpValidating"
        :label="$t('login')"
        @click="login()"
      />
    </template>

    <!-- Request OTP -->
    <div v-else>
      <VyButton
        type="submit"
        fluid
        theme="solid"
        color="primary"
        shape="rounded"
        size="lg"
        :loading="otpRequesting"
        :label="$t('get_one_time_password')"
        :disabled="!isWhatsappCloudCheckoutChannel && !hasCredits"
      />

      <p v-if="!hasCredits" class="mt-1 text-xs text-danger-300">
        ({{ $t("disabled_no_credits") }})
      </p>
    </div>
  </Form>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useBizStore } from "@/store/index.js";
import verifyOtp from "@/graph/verify-otp.gql";
import { useVepaarStore } from "@/store/store.js";
import sendOtpOnWhatsapp from "@/graph/store/send-otp-on-whatsapp.gql";
import { useFetchCustomer } from "@/composables/useFetchCustomer";
import { useAuthStore } from "@/store/auth.js"; // Adjust the path as needed

export default {
  emits: ["success"],

  data() {
    return {
      otpRequesting: false,
      otpRequested: false,
      otpValidating: false,
      otp: null,
      mobileNumber: null,
      localCallingCode: null,
      errors: null,
      mobileNumberWithCallingCode: null,
    };
  },

  computed: {
    ...mapState(useBizStore, ["hasCredits", "callingCode", "subdomain"]),
    ...mapState(useVepaarStore, ["hash", "isWhatsappCloudCheckoutChannel"]),
  },

  created() {
    this.localCallingCode = this.callingCode;
  },

  methods: {
    ...mapActions(useAuthStore, ["onLogin"]),

    getOtp() {
      this.errors = null;
      this.otpRequesting = true;

      const variables = {
        input: {
          mobileNumberWithCallingCode: this.mobileNumberWithCallingCode,
          storeHash: this.hash,
          usedFor: "customer_login",
        },
      };

      const { mutate } = useMutation(sendOtpOnWhatsapp, {
        variables,
      });

      return mutate()
        .then(() => {
          this.otpRequested = true;
          this.otpRequesting = false;
        })
        .catch(({ graphQLErrors }) => {
          this.errors = graphQLErrors;
          this.otpRequesting = false;
        })
        .finally(() => {
          this.otpRequesting = false;
        });
    },

    login() {
      this.errors = null;
      this.otpValidating = true;
      const { fetchCustomer } = useFetchCustomer();
      const variables = {
        mobileNumberWithCallingCode: this.mobileNumberWithCallingCode,
        otp: String(this.otp),
        storeHash: this.hash,
      };

      const { mutate } = useMutation(verifyOtp, {
        variables,
      });

      return mutate()
        .then(({ data }) => {
          return data.loginWithMobileOtp.accessToken;
        })
        .then(async (token) => {
          await this.onLogin(token);
          await fetchCustomer();
          this.$emit("success");
        })
        .catch(({ graphQLErrors }) => {
          this.errors = graphQLErrors;
        })
        .finally(() => {
          this.otpValidating = false;
        });
    },
  },
};
</script>
