<template>
  <div class="flex">
    <FormField class="w-36 pe-2" :label="$t('country_code')">
      <div
        class="flex items-center bg-gray-100 rounded mobile-input ps-2 dark:bg-gray-850"
      >
        <Icon name="MdiPlus" class="w-6 h-6 text-gray-500" />
        <Field
          v-model="localCallingCode"
          class="bg-gray-100 rounded textbox textbox--no-arrow dark:border-none"
          type="number"
          name="country code"
          rules="required"
        />
        <ErrorMessage name="country_code" class="field__error" />
      </div>
    </FormField>

    <FormField class="flex-grow" :label="$t('whatsapp_number')">
      <FormTextbox
        v-model="mobileNumber"
        type="number"
        class="textbox--no-arrow"
        name="whatsapp number"
        rules="required"
      />
    </FormField>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useBizStore } from "@/store/index.js";

export default {
  emits: ["updateCallingCode"],

  data() {
    return {
      localCallingCode: null,
      mobileNumber: null,
    };
  },

  computed: {
    ...mapState(useBizStore, ["callingCode"]),

    value() {
      // Remove leading Zeros
      const mobileNo = this.mobileNumber
        ? String(this.mobileNumber).replace(/^0+/, "")
        : "";
      const callingCode = this.localCallingCode
        ? String(this.localCallingCode).replace(/^0+/, "")
        : "";
      return callingCode + mobileNo || "";
    },
  },

  watch: {
    value(newValue) {
      this.$emit("updateCallingCode", newValue);
    },
  },

  created() {
    this.localCallingCode = this.callingCode;
  },
};
</script>
<style>
.mobile-input .textbox {
  outline: none !important;
  @apply w-full h-9 text-sm dark:bg-gray-850 dark:text-gray-300 dark:border dark:border-gray-700;
}

.mobile-input .textbox--no-arrow {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.mobile-input .textbox--default {
  @apply bg-gray-100 border-0 rounded px-3;

  &:hover,
  &:focus {
    @apply ring-2 ring-primary-200 ring-inset dark:ring-gray-600 dark:border-none;
  }
}
</style>
